<template>
  <div class="mod-config">
      
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">用户账号</td>
          <td class="valueTd">
            <el-input v-model="dataForm.userNo" placeholder="用户账号" clearable></el-input>
          </td>
          <td class="titleTd width80px">用户名称</td>
          <td class="valueTd">
            <el-input v-model="dataForm.userName" placeholder="用户名称" clearable></el-input>
          </td>
        </tr>
      </table>
    </el-form>
    
    <div class="wxts_msg_search" >
      <div class="f_t">
        <el-button-group  >
        <el-button type="success" @click="search()" :disabled="dataListLoading">查询</el-button>
        <el-button v-if="isAuth('sys:deptuser:save')" type="primary" @click="addOrUpdateHandle()" :disabled="dataListLoading">新增</el-button>
        <el-button v-if="isAuth('sys:deptuser:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 || dataListLoading">批量删除</el-button> 
        </el-button-group>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column> 
      <el-table-column
        prop="userNo"
        header-align="center"
        align="center"
        label="用户账号"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        label="用户名称"
      >
      </el-table-column> 
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="danger"
            >禁用
          </el-tag>
          <el-tag v-else size="small">正常</el-tag>
        </template>
      </el-table-column> 
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="180"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('sys:deptuser:update') && scope.row.userId != userId"
            type="text"
            class="update-button"
            size="small"
            @click="addOrUpdateHandle(scope.row.userId)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('sys:deptuser:delete') && scope.row.userId != userId"
            type="text"
            class="del-button"
            size="small"
            @click="deleteHandle(scope.row)"
            >删除</el-button
          >
          <el-button
            v-if="isAuth('sys:deptuser:resetPass') && scope.row.userId != userId"
            type="text"
            size="small"
            @click="resetPassHandle(scope.row.userId,scope.row.username)"
            >密码重置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import { encryptDes } from "@/utils/des";
import AddOrUpdate from "./add-or-update";
import { validatorPassword } from '@/utils/validate'
import { getList,resetPass,deleteUser } from '@/api/sys/user'

export default {
  components: {
    AddOrUpdate
  },
  data() {
    return {
      dataForm: {
        userName: "",
        userNo: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },

  activated() {
    //this.getDataType()
    this.getDataList();
  },
  computed: { 
    userId: {
      get() {
        return this.$store.state.user.id;
      } 
    }
  },
  methods: {
    refresh() {
      // this.$router.push({
      //   name: "sys_user",
      //   query: {
      //     key: this.dataForm.userName,
      //     pageIndex: this.pageIndex,
      //     pageSize: this.pageSize
      //   }
      // });
    },
    search() {
      this.pageIndex = 1;
      this.getDataList();
      this.refresh();
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      getList({
          page: this.pageIndex,
          limit: this.pageSize,
          userNo: this.dataForm.userNo,
          username: this.dataForm.userName
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount

          if(data.data.page != this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          if(data.msg) {
            this.$message.error(data.msg)
          }
          this.dataList = []
          this.totalCount = 0
          this.pageIndex = 1
        }
        
      }).finally(()=>{
        this.dataListLoading = false;
      });
       
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
      this.refresh();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
      this.refresh();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      }); 
    },
    //用户密码重置
    resetPassHandle(id,username) {
      this.$prompt("请输入用户 [ " + username + " ] 的新密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        closeOnHashChange: false, 
        // inputPattern: /\S/,
        inputValidator: validatorPassword,
        inputErrorMessage: '密码要求长度8~20位,并且不能为纯数字或纯字母!'
      })
        .then(({ value }) => {
          const n_pass = encryptDes(value); // encrypt(value);
          resetPass({
              userId: id,
              newPass: n_pass
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1000
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    }, 
    // 删除
    deleteHandle(row) {
      var userIds = row
        ? [row.userId]
        : this.dataListSelections.map(item => {
            return item.userId;
          });

      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        deleteUser(userIds).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1000
              });
              this.getDataList();
            } else {
              this.$message.error(data.msg);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
